import { gql } from 'graphql-tag'

export const CREATE_BDESE_SECTIONS_MUTATION = gql`
  mutation CREATE_BDESE_SECTIONS_MUTATION(
    $id: String!
    $sections: [BdeseSectionInputDto!]!
  ) {
    createBdeseSections(id: $id, sections: $sections) {
      id
      order
      content
      title
      description
    }
  }
`
